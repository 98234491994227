import React, { useRef, useEffect, useState, useCallback } from "react";
import Styled from "styled-components";
import HomeCard from "../HomeCard";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../redux/loadReducer";
import { useLocation } from "react-router-dom"; // Import useLocation hook

const Container = Styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default: 4 columns */
  gap: 20px;
  padding: 20px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr); /* Tablet: 3 columns */
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Tablet Portrait: 2 columns */
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr); /* Mobile: 1 column */
  }

  height: calc(100vh - 60px);
  overflow-y: scroll;
  &::-webkit-scrollbar{
    display:none;
  };
  
`;

const Home = () => {
  const containerRef = useRef(null);
  const [docs, setDocs] = useState([]); // State to store documents
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [totalPages, setTotalPages] = useState(1); // State for total pages
  const [isLoading, setIsLoading] = useState(false); // Prevent multiple calls
  const dispatch = useDispatch();
  const location = useLocation(); // Get the current URL location
  const getSearchQuery = () => {
    const params = new URLSearchParams(location.search);
    return params.get("search") || ""; // Return the search term or an empty string
  };

  const fetchDocs = async (page, searchTerm) => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/docs/getAllDocs?page=${page}&limit=8&search=${searchTerm}`
      );
      const { docs: newDocs, totalPages: fetchedTotalPages } = response.data;
      console.log(response.data);
      setDocs((prevDocs) => (page === 1 ? newDocs : [...prevDocs, ...newDocs])); // Reset docs on new search
      setTotalPages(fetchedTotalPages); // Set total pages
    } catch (error) {
      console.error("Error fetching documents:", error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleScroll = useCallback(() => {
    const element = containerRef.current;

    if (
      element.scrollHeight - element.scrollTop <= element.clientHeight + 11 &&
      currentPage < totalPages
    ) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [currentPage, totalPages]);

  useEffect(() => {
    const searchTerm = getSearchQuery();
    setDocs([]); // Reset documents on new search
    setCurrentPage(1); // Reset current page
    fetchDocs(1, searchTerm); // Fetch documents for the new search term
  }, [location.search]); // Re-fetch docs when the search query in the URL changes

  useEffect(() => {
    if (!isLoading && currentPage > 1) {
      fetchDocs(currentPage, getSearchQuery());
    }
  }, [currentPage]); // Fetch next page on scroll

  useEffect(() => {
    const container = containerRef.current;

    const debounceScroll = (e) => {
      clearTimeout(container.debounce);
      container.debounce = setTimeout(() => {
        handleScroll();
      }, 200);
    };

    container.addEventListener("scroll", debounceScroll);

    return () => {
      container.removeEventListener("scroll", debounceScroll); // Clean up the event listener
    };
  }, [handleScroll]);

  return (
    <Container ref={containerRef}>
      {docs.map((doc) => (
        <HomeCard
          key={doc._id}
          title={doc.docTitle}
          likeCount={doc.likeCount}
          img={doc.thumbnail}
          id={doc._id}
        />
      ))}
    </Container>
  );
};

export default Home;
