import React from "react";
import Styled from "styled-components";
import Loader from "../Loader";
const Container = Styled.div`
display:flex;
align-items:center;
justify-content:center;
width:100%;
height:calc(100vh - 60px);
`;

const Buffering = () => {
  return (
    <Container>
      <Loader size="40px"></Loader>
    </Container>
  );
};

export default Buffering;
