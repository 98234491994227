import Styled from "styled-components";
import { useContext } from "react";
import { Mobile } from "../responsive";
import { ContextProvider } from "../App";

const ImageContainer = Styled.div`
`;
const Image = Styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const Heading1Container = Styled.div`
padding: 25px 0;
`;

const Heading2Container = Styled.div`
padding: 20px 0;
`;

const Heading3Container = Styled.div`
text-align:justify;
`;
const ListContainer = Styled.div`
text-align:justify;
padding:20px;
`;

const Heading1 = Styled.span`
  font-size: 25px;
  font-weight: bold;
`;

const Heading2 = Styled.span`
font-size: 23px;
font-weight: bold;`;

const Paragraph = Styled.span`
font-size: 22px;
margin-right:20px;
margin-left:20px;
line-height: 1.5;`;

const SubImageContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
padding:10px 25%;
${Mobile({ padding: "10px 10px" })};`;

const P = Styled.span`
font-size:22px;`;

const Li = Styled.li`
margin-top:10px;`;

const Container = Styled.div`
padding:20px;
 padding-bottom:0px;
 
color:${(props) => props.theme.fontPri};`;

const TitleContainer = Styled.div`
`;

const Title = Styled.h1`
color:${(props) => props.theme.fontPri};`;

const SubtitleContainer = Styled.div`
`;

const Subtitle = Styled.h2`
color:${(props) => props.theme.fontPri};`;
const RenderContent = ({ blogData }) => {
  const { colorTheme } = useContext(ContextProvider);
  return (
    <Container theme={colorTheme}>
      <div>
        <TitleContainer>
          <Title>{blogData?.contents?.docTitle}</Title>
        </TitleContainer>
        <SubtitleContainer>
          <Subtitle>{blogData?.contents?.subtitle}</Subtitle>
        </SubtitleContainer>
      </div>
      <div>
        {blogData?.contents?.NoteData?.map((item, index) => {
          if (item.type === "heading1") {
            return (
              <Heading1Container>
                <Heading1 key={index}>{item.content}</Heading1>
              </Heading1Container>
            );
          } else if (item.type === "heading2") {
            return (
              <Heading2Container>
                <Heading2 key={index}>{item.content}</Heading2>
              </Heading2Container>
            );
          } else if (item.type === "image") {
            return (
              <ImageContainer>
                <Image key={index} src={item.content} />
              </ImageContainer>
            );
          } else if (item.type === "subimage") {
            return (
              <SubImageContainer>
                <ImageContainer>
                  <Image key={index} src={item.content} />
                </ImageContainer>
              </SubImageContainer>
            );
          } else if (item.type === "paragraph") {
            return (
              <Heading3Container>
                <Paragraph key={index}>{item.content}</Paragraph>
              </Heading3Container>
            );
          } else if (item.type === "pointers") {
            return (
              <ListContainer>
                {item.content.split("\n").map((item) => (
                  <Li key={index}>
                    <P>{item}</P>
                  </Li>
                ))}
              </ListContainer>
            );
          } else if (item.type === "code") {
            return (
              <ListContainer>
                {/* <CopyBlock
                  text={item.id.content}
                  language={item.id.note}
                  showLineNumbers={true}
                  theme={dracula}
                  wrapLines
                /> */}
              </ListContainer>
            );
          }
          return null;
        })}
      </div>
    </Container>
  );
};

export default RenderContent;
