import React, { useContext } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../../App";

const Container = Styled.div`
width:100%;
`;

const InputBox = Styled.div`
background-color:${(props) => props.bgColor};
/* margin-top:10px; */
border-radius:5px;
/* padding:5px 12px; */
box-sizing:border-box;
border:none;
/* margin-bottom:20px; */
`;
const InputBox2 = Styled.div`
background-color:${(props) => props.bgColor};
margin-top:10px;
border-radius:5px;
padding:5px 12px;
box-sizing:border-box;
border:none;
margin-bottom:20px;
`;

const Input = Styled.input`
background-color:${(props) => props.bgColor};
font-size:16px;
color:${(props) => props.ftColor};

width:100%;
border:none;
height:30px;
&:focus{
  outline:none;
};
&:-webkit-autofill {
    -webkit-box-shadow:${(props) => `0 0 0 50px ${props.bgColor} inset`};
    -webkit-text-fill-color: ${(props) => props.ftColor};
};
`;

const InputContainer = ({
  value = "",
  input,
  setInput,
  label,
  type,
  placeholder,
  marginLess = false,
}) => {
  const { colorTheme } = useContext(ContextProvider);

  const { backPri, fontPri } = colorTheme;
  return (
    <Container>
      {marginLess ? (
        <InputBox bgColor={backPri}>
          <Input
            value={value}
            bgColor={backPri}
            ftColor={fontPri}
            placeholder={placeholder}
            autoComplete={false}
            type={type}
            onChange={(e) => setInput(e.target.value)}
          />
        </InputBox>
      ) : (
        <InputBox2 bgColor={backPri}>
          <Input
            value={value}
            bgColor={backPri}
            ftColor={fontPri}
            placeholder={placeholder}
            autoComplete={false}
            type={type}
            onChange={(e) => setInput(e.target.value)}
          />
        </InputBox2>
      )}
    </Container>
  );
};

export default InputContainer;
