import Styled from "styled-components";
import Markdown from "react-markdown";
const Container = Styled.div`
padding:20px;
display:flex;
flex-wrap:wrap;
justify-content:start;
`;

const Home = () => {
  return (
    <Container>
      hello world
      <Markdown>{}</Markdown>
    </Container>
  );
};

export default Home;
