import React, { useContext, useState, useEffect } from "react";
import Styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Mobile } from "../responsive";
import { ContextProvider } from "../App";
import { logout } from "../redux/userReducer";
import { useNavigate } from "react-router-dom";
import RenderBlog from "../components/RenderBlog";
import { unsetActiveNote } from "../redux/activeNoteReducer";
import PaymentRoundedIcon from "@mui/icons-material/PaymentRounded";
import Notes from "../components/Notes";
import Axios from "axios";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { loginSuccess } from "../redux/userReducer";
import Buffering from "../components/General/Buffering";

const ParentContainer = Styled.div`
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  visibility: ${(props) => (props.toggle ? "visible" : "hidden")};
  background-color:${(props) => props.theme.backPri};
  backdrop-filter: blur(20px);
  display: flex;
  padding:30px;
  padding-bottom:0px;
  ${Mobile({ padding: "7px" })};
  box-sizing:border-box;
  &::-webkit-scrollbar{
    display:none;
  };
  align-items:flex-start;
  
`;

const Left = Styled.div`
  flex: 1;
  border-radius:10px;
  height:calc(100vh - 30px);
  overflow:scroll;
  &::-webkit-scrollbar{
    display:none;
  };
   ${Mobile({
     height: (props) =>
       props.isViewNoteActive ? "calc(50vh )" : "calc(100vh - 30px)",
   })};
`;

const Right = Styled.div`
  flex: 0.7;
  color: ${(item) => item.theme.fontPri};
  box-sizing:border-box;
  &::-webkit-scrollbar{
    display:none;
  };
  ${Mobile({ display: "none" })};
`;

const ActionContainer = Styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  justify-content:center;
  padding:20px;
  overflow:scroll;
  ${Mobile({ padding: "5px" })};
  box-sizing:border-box;
  &::-webkit-scrollbar{
    display:none;
  };
  `;
const ActionContainerMobile = Styled.div`
  flex-direction: column;
  align-items:center;
  justify-content:center;
  padding:20px;
  overflow:scroll;
  display:none;
  ${Mobile({ padding: "5px", display: "flex" })};
  box-sizing:border-box;
  &::-webkit-scrollbar{
    display:none;
  };
  `;
const NotesRenderMobile = Styled.div`
display:none;
position:absolute;
height:50vh;
width:100%; 
bottom:0px;
left:0px;
right:0px;
padding-left:5px;
padding-right:5px;
box-sizing:border-box;
${Mobile({ display: "flex" })};
`;
const NoteContainer = Styled.div`
width:100%;`;
const ButtonContainer = Styled.div`
  width:100%;
  display:flex;
  
  align-items:center;
  justify-content:center;
 

  `;

const Button = Styled.button`
  width:80%;
  padding:15px;
  background-color: ${(item) => item.theme.mainColor};
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size:16px;
  display:flex;
  align-items:center;
  justify-content:center;
  font-weight:bolder;
  
  ${Mobile({ width: "100%" })};
  transition: transform 0.2s ease;
  &:active {
  transform: scale(0.95); /* Shrinks the button slightly */
  };
    `;

const CartContainer = Styled.div`
    flex:1;
    padding:5px;`;
const BuyContainer = Styled.div`
    padding:5px;
    flex:1;`;

const ReadDoc = () => {
  const dispatch = useDispatch();
  const activeNote = useSelector((state) => state.activenote);
  const { colorTheme, setNotification } = useContext(ContextProvider);
  const user = useSelector((state) => state.user.currentUser);
  const [docData, setDocData] = useState({});
  const [viewNote, setViewNote] = useState(false);
  const [likeLoad, setLikeLoad] = useState(false);
  const [paymentLoad, setPaymentLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isLoggedIn = user ? true : false;
  const isLiked = user
    ? user?.liked?.some((item) => item.id === activeNote.docId)
    : false;

  const isPurchased = user
    ? user?.purchased?.some((item) => item.id === activeNote.docId)
    : false;

  const handleClick = (e) => {
    if (e.target.classList.contains("parent")) {
      dispatch(unsetActiveNote());
    }
  };
  const IconStyle = {
    height: "30px",
    width: "30px",
    paddingRight: "5px",
  };
  useEffect(() => {
    const handlePopState = () => {
      dispatch(unsetActiveNote());
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = isLoggedIn
          ? await Axios.get(
              `${process.env.REACT_APP_API_URL}/api/v1/docs/getDocsByIdLoggedIn/${activeNote.docId}`,
              {
                headers: {
                  Authorization: `Bearer ${user.access_token}`,
                },
              }
            )
          : await Axios.get(
              `${process.env.REACT_APP_API_URL}/api/v1/docs/getDocsByIdUnLogged/${activeNote.docId}`
            );
        setDocData(res.data);
      } catch (error) {
        setNotification("failure", error.message);
      }
      setLoading(false);
    };
    activeNote.toggle && fetchData();
  }, [activeNote.toggle]);
  const checkoutHandler = async (amount) => {
    if (!user) {
      setNotification("failure", "Please login to continue");
      return;
    }
    setPaymentLoad(true);
    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/buy/checkout`,
        {
          notesToBuy: [activeNote.docId],
        },
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      if (data.total === 0) {
        setNotification("success", "Purchased With Credits");
        const updatedUserDetail = {
          ...user,
          credits: 0,
          cart: [],
        };
        dispatch(loginSuccess(updatedUserDetail));
        navigate("/purchased");
        return;
      }
      const options = {
        key: process.env.REACT_APP_RAZORPAY_API_KEY,
        amount: data.total,
        currency: "INR",
        name: "DocShare",
        image:
          "https://docshare-profile-images.s3.ap-south-1.amazonaws.com/1730221008698",
        order_id: data.orderId,
        callback_url: `${process.env.REACT_APP_API_URL}/api/v1/buy/verifyPayment`,
        prefill: {
          name: user.name,
          email: user.email,
        },
        notes: {
          address: "Shri Jagdish Nagar, Hardoi, UP, India, 241001",
        },
        theme: {
          color: colorTheme.mainColor,
        },
      };
      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      if (error?.response?.status === 411) {
        setNotification("failure", "Sessions Exceeded! Please login again");
        dispatch(logout());
        navigate("/login");
        return;
      }
      setNotification("failure", error?.response?.data?.message);
    }
    setPaymentLoad(false);
  };
  const handleLike = async () => {
    if (!user) {
      setNotification("failure", "Please login to continue");
      return;
    }
    if (user && user.liked.includes(activeNote.docId)) {
      setNotification("info", "Document already liked");
      return;
    }
    setLikeLoad(true);
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/docs/likeDocById/${activeNote.docId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user?.access_token}`,
          },
        }
      );

      dispatch(loginSuccess({ ...user, liked: response?.data?.liked }));
      setNotification("success", response.data.message);
    } catch (error) {
      if (error?.response?.status === 411) {
        setNotification("failure", "Sessions Exceeded! Please login again");
        dispatch(logout());
        navigate("/login");
        return;
      }
      setNotification("failure", error?.response?.data?.message);
    }
    setLikeLoad(false);
  };
  const handleToggleNote = () => {
    if (!user) {
      setNotification("failure", "Please login to continue");
      return;
    }
    setViewNote((item) => !item);
  };
  return (
    <ParentContainer
      theme={colorTheme}
      className="parent"
      onClick={(e) => handleClick(e)}
      toggle={activeNote.toggle}
    >
      <Left isViewNoteActive={viewNote} theme={colorTheme}>
        <ActionContainerMobile>
          <ButtonContainer>
            <CartContainer>
              <Button
                theme={colorTheme}
                onClick={() => dispatch(unsetActiveNote())}
              >
                <CloseRoundedIcon style={IconStyle} />
                Close
              </Button>
            </CartContainer>
            <BuyContainer>
              <Button
                paymentLoad={paymentLoad}
                theme={colorTheme}
                onClick={isPurchased ? () => null : checkoutHandler}
              >
                <PaymentRoundedIcon style={IconStyle} />
                {isPurchased ? "Purchased" : "Buy"}
              </Button>
            </BuyContainer>
          </ButtonContainer>
          <ButtonContainer>
            <CartContainer>
              <Button
                theme={colorTheme}
                likeLoad={likeLoad}
                onClick={handleLike}
              >
                {isLiked ? (
                  <FavoriteIcon style={IconStyle} />
                ) : (
                  <FavoriteBorderOutlinedIcon style={IconStyle} />
                )}
                {isLiked ? "Liked" : "Like"}
              </Button>
            </CartContainer>
            <BuyContainer>
              <Button theme={colorTheme} onClick={handleToggleNote}>
                <DescriptionRoundedIcon style={IconStyle} />
                Note
              </Button>
            </BuyContainer>
          </ButtonContainer>
        </ActionContainerMobile>
        {loading ? <Buffering /> : <RenderBlog docData={docData} />}
      </Left>
      <Right theme={colorTheme}>
        {viewNote ? (
          <ActionContainer>
            <Notes toggleNote={setViewNote} />
          </ActionContainer>
        ) : (
          <ActionContainer>
            <ButtonContainer>
              <CartContainer>
                <Button
                  theme={colorTheme}
                  onClick={() => dispatch(unsetActiveNote())}
                >
                  <CloseRoundedIcon style={IconStyle} />
                  Close
                </Button>
              </CartContainer>
              <BuyContainer>
                <Button
                  paymentLoad={paymentLoad}
                  theme={colorTheme}
                  onClick={isPurchased ? () => null : checkoutHandler}
                >
                  <PaymentRoundedIcon style={IconStyle} />
                  {isPurchased ? "Purchased" : "Buy"}
                </Button>
              </BuyContainer>
            </ButtonContainer>
            <ButtonContainer>
              <CartContainer>
                <Button
                  likeLoad={likeLoad}
                  theme={colorTheme}
                  onClick={handleLike}
                >
                  {isLiked ? (
                    <FavoriteIcon style={IconStyle} />
                  ) : (
                    <FavoriteBorderOutlinedIcon style={IconStyle} />
                  )}
                  {isLiked ? "Liked" : "Like"}
                </Button>
              </CartContainer>
              <BuyContainer>
                <Button theme={colorTheme} onClick={handleToggleNote}>
                  <DescriptionRoundedIcon style={IconStyle} />
                  Note
                </Button>
              </BuyContainer>
            </ButtonContainer>
          </ActionContainer>
        )}
      </Right>
      {viewNote && (
        <NotesRenderMobile>
          <NoteContainer>
            <Notes isMobile={true} toggleNote={setViewNote} />
          </NoteContainer>
        </NotesRenderMobile>
      )}
    </ParentContainer>
  );
};

export default ReadDoc;
