import Styled from "styled-components";
import { Mobile } from "../responsive";
import { CopyBlock, dracula } from "react-code-blocks";
const ImageContainer = Styled.div`
`;

const Image = Styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const Heading1Container = Styled.div`
padding: 5px 0;
`;

const Heading2Container = Styled.div`
padding: 5px 0;
`;

const Heading3Container = Styled.div`
padding: 5px 0;
`;
const ListContainer = Styled.div`
text-align:justify;
padding-left:20px;
`;

const Heading1 = Styled.span`
  font-size: 20px;
  font-weight: bold;
`;

const Heading2 = Styled.span`
font-size: 18px;
font-weight: bold;`;

const Paragraph = Styled.span`
font-size: 15px;
margin-right:20px;
margin-left:20px;
line-height: 1;`;

const SubImageContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
padding:10px 25%;
${Mobile({ padding: "10px 10px" })};`;

const Li = Styled.li`
margin-top:10px;`;

const renderContent = ({ blogData }) => {
  return (
    <div>
      <div>
        {blogData?.contents?.map((item, index) => {
          if (item.type === "heading1") {
            return (
              <Heading1Container>
                <Heading1 key={index}>{item.content}</Heading1>
              </Heading1Container>
            );
          } else if (item.type === "heading2") {
            return (
              <Heading2Container>
                <Heading2 key={index}>{item.content}</Heading2>
              </Heading2Container>
            );
          } else if (item.type === "heading3") {
            return (
              <Heading2Container>
                <Heading2 key={index}>{item.content}</Heading2>
              </Heading2Container>
            );
          } else if (item.type === "image") {
            return (
              <ImageContainer>
                <Image key={index} src={item.content} />
              </ImageContainer>
            );
          } else if (item.type === "subimage") {
            return (
              <SubImageContainer>
                <ImageContainer>
                  <Image key={index} src={item.content} />
                </ImageContainer>
              </SubImageContainer>
            );
          } else if (item.type === "paragraph") {
            return (
              <Heading3Container>
                <Paragraph key={index}>{item.content}</Paragraph>
              </Heading3Container>
            );
          } else if (item.type === "list") {
            return (
              <ListContainer>
                <Li key={index}>
                  <Paragraph>{item.content}</Paragraph>
                </Li>
              </ListContainer>
            );
          } else if (item.type === "code") {
            return (
              <ListContainer>
                <CopyBlock
                  text={item.content}
                  language={item.note}
                  showLineNumbers={true}
                  theme={dracula}
                  wrapLines
                />
              </ListContainer>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default renderContent;
