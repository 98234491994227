import React, { useContext } from "react";
import Styled from "styled-components";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Mobile } from "../responsive";
import { ContextProvider } from "../App";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ShoppingBagRoundedIcon from "@mui/icons-material/ShoppingBagRounded";
import ThumbUpRoundedIcon from "@mui/icons-material/ThumbUpRounded";
import DescriptionRoundedIcon from "@mui/icons-material/DescriptionRounded";
import PaymentRoundedIcon from "@mui/icons-material/PaymentRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import { toggleSidebar } from "../redux/sideReducer";
import { useDispatch } from "react-redux";

const Container = Styled.div`
font-family:'Nunito',sans-serif;
flex:0.7;
height:calc(100vh - 60px);
position:sticky;
top:60px;
overflow:scroll;
&::-webkit-scrollbar{
  display:none;
};
background-color:${(props) => props.theme.backPri};
color:${(props) => props.theme.fontPri};
${Mobile({ display: "none" })};
`;

const Wrapper = Styled.div`
display:flex;
align-items:flex-start;
justify-content:center;
padding:10px;
${Mobile({ padding: "10px 2px" })};
`;

const Table = Styled.table`
border:none;
width:90%;`;

const Tr = Styled.tr`
margin-top:5px;
padding-top:10px; 
padding-bottom:10px; 
display:flex;
align-items:center;
border-radius:20px;
background-color:${(props) =>
  props.location === props.name && props.theme.backSec};
justify-content:center;
&:hover{
  cursor:pointer;
  background-color:${(props) => props.theme.backSec};;

};`;

const TdIcon1 = Styled.td`
padding-left:5px;
display:flex;
align-items:center;
justify-content:center;
${Mobile({ paddingLeft: "0px" })};
flex:1;`;

const TdText = Styled.td`
padding-left:10px;
${Mobile({ display: "none" })};
flex:3;`;

const TdIcon2 = Styled.td`
${Mobile({ display: "none" })};
flex:1;`;

const Heading = Styled.span`
font-size:18px;
`;
const Sidebar = () => {
  const sidebar = useSelector((state) => state.sidebar.toggle);
  const { colorTheme } = useContext(ContextProvider);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const IconStyle = {
    height: "30px",
    width: "30px",
  };
  let Location = useLocation();
  Location = Location.pathname.split("/")[1];

  const handleClick = (path) => {
    navigate("/" + path);
    dispatch(toggleSidebar());
  };
  return (
    <Container theme={colorTheme} className="child" sidebar={sidebar}>
      <Wrapper>
        <Table>
          <Tr
            location={Location}
            theme={colorTheme}
            name={"home"}
            onClick={() => {
              handleClick("home");
            }}
          >
            <TdIcon1>
              <HomeRoundedIcon style={IconStyle} />
            </TdIcon1>
            <TdText>
              <Heading>Home</Heading>
            </TdText>
            <TdIcon2></TdIcon2>
          </Tr>
          <Tr
            location={Location}
            theme={colorTheme}
            name={"liked"}
            onClick={() => {
              handleClick("liked");
            }}
          >
            <TdIcon1>
              <ThumbUpRoundedIcon style={IconStyle} />
            </TdIcon1>
            <TdText>
              <Heading>Liked</Heading>
            </TdText>
            <TdIcon2></TdIcon2>
          </Tr>
          <Tr
            location={Location}
            name={"mynotes"}
            theme={colorTheme}
            onClick={() => {
              handleClick("mynotes");
            }}
          >
            <TdIcon1>
              <DescriptionRoundedIcon style={IconStyle} />
            </TdIcon1>
            <TdText>
              <Heading>Notes</Heading>
            </TdText>
            <TdIcon2></TdIcon2>
          </Tr>
          <Tr
            location={Location}
            name={"cart"}
            theme={colorTheme}
            onClick={() => {
              handleClick("cart");
            }}
          >
            <TdIcon1>
              <ShoppingCartRoundedIcon style={IconStyle} />
            </TdIcon1>
            <TdText>
              <Heading>Cart</Heading>
            </TdText>
            <TdIcon2></TdIcon2>
          </Tr>
          <Tr
            location={Location}
            name={"orders"}
            theme={colorTheme}
            onClick={() => {
              handleClick("orders");
            }}
          >
            <TdIcon1>
              <ShoppingBagRoundedIcon style={IconStyle} />
            </TdIcon1>
            <TdText>
              <Heading>Orders</Heading>
            </TdText>
            <TdIcon2></TdIcon2>
          </Tr>

          <Tr
            location={Location}
            name={"purchased"}
            theme={colorTheme}
            onClick={() => {
              handleClick("purchased");
            }}
          >
            <TdIcon1>
              <PaymentRoundedIcon style={IconStyle} />
            </TdIcon1>
            <TdText>
              <Heading>Purchased</Heading>
            </TdText>
            <TdIcon2></TdIcon2>
          </Tr>
        </Table>
      </Wrapper>
    </Container>
  );
};

export default Sidebar;
