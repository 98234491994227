import React, { useContext } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../../App";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
const Container = Styled.div`
width:100%;
`;

const InputBox = Styled.div`
background-color:${(props) => props.bgColor};
margin-top:10px;
border-radius:5px;
padding:5px 12px;
box-sizing:border-box;
border:none;
margin-bottom:20px;
display:flex;
align-items:center;
justify-content:center;
`;

const Input = Styled.input`
background-color:${(props) => props.bgColor};
font-size:16px;
color:${(props) => props.ftColor};
width:100%;
border:none;
height:30px;
&:focus{
  outline:none;
};
&:-webkit-autofill {
    -webkit-box-shadow:${(props) => `0 0 0 50px ${props.bgColor} inset`};
    -webkit-text-fill-color: ${(props) => props.ftColor};
};
`;
const ToggleIcon = Styled.div`
cursor:pointer;
height:35px;
width:35px;
margin-right:0px;
`;
const Label = Styled.label`
color:${(props) => props.ftColor};
`;

const InputContainer = ({
  input,
  setInput,
  label,
  type,
  placeholder,
  handleTogglePassword,
  passwordVisible,
}) => {
  const { colorTheme, theme } = useContext(ContextProvider);
  const IconStyle = {
    height: "30px",
    width: "30px",
    color: theme === "dark" ? "white" : "grey",
  };
  const { backPri, fontPri } = colorTheme;
  return (
    <Container>
      {/* <Label ftColor={fontPri}>
        {label.charAt(0).toUpperCase() + label.slice(1, label.length)}
      </Label> */}
      <InputBox bgColor={backPri}>
        <Input
          bgColor={backPri}
          ftColor={fontPri}
          placeholder={placeholder}
          type={type}
          onChange={(e) => setInput(e.target.value)}
        />
        <ToggleIcon onClick={handleTogglePassword}>
          {passwordVisible ? (
            <VisibilityIcon style={IconStyle} />
          ) : (
            <VisibilityOffIcon style={IconStyle} />
          )}
        </ToggleIcon>
      </InputBox>
    </Container>
  );
};

export default InputContainer;
