import React, { useContext, useState } from "react";
import Styled from "styled-components";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toggleAccountBar } from "../redux/accountReducer";
import { Mobile } from "../responsive";
import { ContextProvider } from "../App";
import LoginRoundedIcon from "@mui/icons-material/LoginRounded";
import HowToRegRoundedIcon from "@mui/icons-material/HowToRegRounded";
import TruncateText from "../utils/TruncateText";
import { logout } from "../redux/userReducer";
import Axios from "axios";
import Loader from "./Loader";
const ParentContainer = Styled.div`
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  visibility: ${(props) => (props.toggle ? "visible" : "hidden")};
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(1px);
  transition: all 0.1s ease;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

const Container = Styled.div`
  margin-top: 65px;
  margin-right: 5px;
  background-color: ${(props) => props.theme.backPri};
  color: ${(props) => props.theme.fontPri};
  border-radius: 20px;
  width: 300px;
  padding: 5px;
  overflow:hidden;
  ${Mobile({ maxWidth: "200px" })};

`;

const InfoRow = Styled.div`
  
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${(props) => props.theme.backSec};
  }
`;

const Icon = Styled.div`
  margin-right: 10px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = Styled.div`
  flex: 1;
  color: ${(props) => props.theme.fontSec};
`;

const Divider = Styled.hr`
  border: none;
  border-top: 2px solid ${(props) => props.theme.backSec};
  
`;

const PersonContainer = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toggle = useSelector((state) => state.accountbar.toggle);
  const user = useSelector((state) => state.user.currentUser);
  const [loading, setLoading] = useState(false);
  const { colorTheme, setNotification } = useContext(ContextProvider);

  const handleClick = (e) => {
    if (e.target.classList.contains("parent")) {
      dispatch(toggleAccountBar());
    }
  };
  const handleSubmit = async () => {
    setLoading(true);
    try {
      await Axios.get(`${process.env.REACT_APP_API_URL}/api/v1/auth/logout`, {
        headers: {
          Authorization: `Bearer ${user?.access_token}`,
        },
      });
    } catch (error) {}
    dispatch(logout());
    setNotification("success", "Logged-Out Successfully");

    navigate("/login");
    dispatch(toggleAccountBar());
    setLoading(false);
  };

  const IconStyle = {
    height: "30px",
    width: "30px",
  };
  const handleClickNavigateProfile = () => {
    navigate("/profile");
    dispatch(toggleAccountBar());
  };
  const handleNavigateLogin = () => {
    navigate("/login");
    dispatch(toggleAccountBar());
  };
  const handleNavigateRegister = () => {
    navigate("/login");
    dispatch(toggleAccountBar());
  };
  return (
    <ParentContainer
      theme={colorTheme}
      className="parent"
      onClick={(e) => handleClick(e)}
      toggle={toggle}
    >
      {user === null ? (
        <Container theme={colorTheme}>
          <InfoRow theme={colorTheme} onClick={handleNavigateLogin}>
            <Icon>
              <LoginRoundedIcon style={IconStyle} />
            </Icon>
            <Text>Login</Text>
          </InfoRow>
          <InfoRow theme={colorTheme} onClick={handleNavigateRegister}>
            <Icon>
              <HowToRegRoundedIcon style={IconStyle} />
            </Icon>
            <Text>Register</Text>
          </InfoRow>
        </Container>
      ) : (
        <Container theme={colorTheme}>
          <InfoRow
            theme={colorTheme}
            onClick={() => handleClickNavigateProfile()}
          >
            <Icon>
              <Person2OutlinedIcon style={IconStyle} />
            </Icon>
            <Text>View Profile</Text>
          </InfoRow>

          <InfoRow theme={colorTheme}>
            <Icon>
              {user?.status === "verified" ? (
                <CheckCircleOutlinedIcon style={IconStyle} />
              ) : (
                <CancelOutlinedIcon style={IconStyle} />
              )}
            </Icon>
            <Text>
              {user?.status === "verified" ? "Verified" : "Unverified"}
            </Text>
          </InfoRow>
          <InfoRow theme={colorTheme}>
            <Icon>
              <MailOutlinedIcon style={IconStyle} />
            </Icon>
            <Text>{TruncateText(user?.email, 15)}</Text>
          </InfoRow>

          <Divider theme={colorTheme} />
          <InfoRow theme={colorTheme} onClick={handleSubmit}>
            <Icon>
              {loading ? <Loader /> : <LogoutIcon style={IconStyle} />}
            </Icon>
            <Text>Logout</Text>
          </InfoRow>
        </Container>
      )}
    </ParentContainer>
  );
};

export default PersonContainer;
