import { useEffect, useState } from "react";

function useSystemTheme() {
  const [theme, setTheme] = useState(() =>
    window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    const handleThemeChange = (e) => {
      setTheme(e.matches ? "dark" : "light");
    };

    // Add event listener for changes in the system theme
    mediaQuery.addEventListener("change", handleThemeChange);

    // Clean up event listener on unmount
    return () => mediaQuery.removeEventListener("change", handleThemeChange);
  }, []);

  return theme;
}

export default useSystemTheme;
