import React, { useContext } from "react";
import Styled from "styled-components";
import { Mobile } from "../../responsive";
import DeleteIcon from "@mui/icons-material/Delete";
import { ContextProvider } from "../../App";

const Product = Styled.div`
border-radius:10px;
margin-bottom:10px;
padding:7px 12px;
display:flex;
align-items:center;
justify-content:center;
background-color:${(props) => props.theme.backSec};
`;

const ProductDetail = Styled.div`
flex:3;
display:flex;
align-items:center;

`;

const Img = Styled.img`
border-radius:10px;
/* height:150px; */
max-width:100px;
`;

const Detail = Styled.div`
flex:1;

padding:20px;
display:flex;
align-items:flex-start;
flex-direction:column;
justify-content:center;`;

const PriceDetail = Styled.div`
flex:1;
display:flex;
align-items:center;
justify-content:flex-start;
`;

const DetailText = Styled.span`
padding:4px;
font-size:20px;
`;

const Amount = Styled.h2`
margin:10px;
`;
const Price = Styled.h1`
margin:10px;
`;
const QuantityContainer = Styled.div`
display:flex;
align-items:center;`;
const AmountContainer = Styled.div``;

const ProductItem = ({ item, handleDelete = null }) => {
  const { colorTheme } = useContext(ContextProvider);
  const iconStyle = {
    height: "40px",
    width: "40px",
    padding: "10px",
    cursor: "pointer",
  };
  return (
    <Product theme={colorTheme} key={item._id}>
      <ProductDetail>
        <Img src={item.id.thumbnail} />
        <Detail>
          <DetailText>{item.id.docTitle}</DetailText>
          <DetailText>{item.id.subtitle}</DetailText>
          <PriceDetail>
            <AmountContainer>
              <Price>₹ 19</Price>
            </AmountContainer>
          </PriceDetail>
        </Detail>
      </ProductDetail>
    </Product>
  );
};

export default ProductItem;
