import React, { useState, useEffect, useContext } from "react";
import Styled from "styled-components";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NoteCard from "../NoteCard";
import { setLoading } from "../../redux/loadReducer";
import { ContextProvider } from "../../App";
import { logout } from "../../redux/userReducer";
import NoItems from "../General/NoItems";
import Buffering from "../General/Buffering";

const Container = Styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default: 4 columns */
  gap: 20px;
  padding: 20px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr); /* Tablet: 3 columns */
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Tablet Portrait: 2 columns */
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr); /* Mobile: 1 column */
  }
`;

const Home = () => {
  const [notes, setNotes] = useState([]);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const { setNotification } = useContext(ContextProvider);
  const navigate = useNavigate();
  const loader = useSelector((state) => state.load.toggle);
  const fetchNotes = async () => {
    dispatch(setLoading(true));
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/notes/getAllNotes`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      );
      setNotes(response.data.notes);
    } catch (error) {
      if (error?.response?.status === 411) {
        setNotification("failure", "Sessions Exceeded! Please login again");
        dispatch(logout());
        navigate("/login");
        return;
      }
      setNotification("failure", "Error Fetching Notes");
    }
    dispatch(setLoading(false));
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  return (
    <>
      {loader ? (
        <Buffering />
      ) : (
        <Container>
          {notes.length > 0 ? (
            notes.map((note) => (
              <NoteCard
                key={note._id}
                title={note.relatedTo.docTitle}
                subtitle={note.relatedTo.subtitle}
                thumbnail={note.relatedTo.thumbnail}
                id={note._id}
              />
            ))
          ) : (
            <NoItems />
          )}
        </Container>
      )}
    </>
  );
};

export default Home;
