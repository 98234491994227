import React, { useContext, useEffect, useState } from "react";
import Styled from "styled-components";
import Axios from "axios";
import { useSelector } from "react-redux";
import { ContextProvider } from "../../App.js";
import { useDispatch } from "react-redux";
import { setLoading } from "../../redux/loadReducer";
import { Mobile } from "../../responsive";
import { useNavigate } from "react-router-dom";
import OrderItem from "../../components/General/OrderItem";
import { logout } from "../../redux/userReducer";
import NoItems from "../General/NoItems";
import Buffering from "../General/Buffering";
const Container = Styled.div`
color:${(props) => props.theme.fontPri};
`;

const Wrapper = Styled.div`
`;

const Bottom = Styled.div`
display:flex;
align-items:top;
justify-content:center;
${Mobile({
  flexDirection: "column",
})};`;

const Info = Styled.div`
padding:20px;
box-sizing:border-box;
flex:3;`;

const Home = () => {
  const [docs, setDocs] = useState([]);
  const user = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setNotification, colorTheme } = useContext(ContextProvider);
  const loader = useSelector((state) => state.load.toggle);
  useEffect(() => {
    const fetchData = async () => {
      dispatch(setLoading(true));
      try {
        const res = await Axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/orders/getAllOrders`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );
        setDocs(res.data.orders);
      } catch (error) {
        if (error?.response?.status === 411) {
          setNotification("failure", "Sessions Exceeded! Please login again");
          dispatch(logout());
          navigate("/login");
          return;
        }
        setNotification("failure", error.response?.data?.message);
      }
      dispatch(setLoading(false));
    };
    fetchData();
  }, []);

  return (
    <Container theme={colorTheme}>
      {loader ? (
        <Buffering />
      ) : docs.length > 0 ? (
        <Wrapper>
          <Bottom>
            <Info>
              {docs.map(
                (doc) => doc._id && <OrderItem item={doc} key={doc._id} />
              )}
            </Info>
          </Bottom>
        </Wrapper>
      ) : (
        <NoItems />
      )}
    </Container>
  );
};

export default Home;
