import React, { useContext, useState } from "react";
import { ContextProvider } from "../App";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { Phone, Mobile } from "../responsive";
import Text from "../components/General/Text";
import Styled from "styled-components";
import Button from "../components/General/Button";
import InputContainer from "../components/General/InputContainer";
import Axios from "axios";
import Icon from "../components/General/Icon";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import InputContainerWithEye from "../components/General/InputContainerWithEye";
import { loginSuccess } from "../redux/userReducer";
import { useNavigate } from "react-router-dom";

const Container = Styled.div`
  height: 100%;
  display: flex;
  align-items:center;
  justify-content: center;
  background-color: ${(props) => props.bgColor};
  box-sizing: border-box;
  overflow:scroll;
&::-webkit-scrollbar{
  display:none;
};
`;

const Wrapper = Styled.div`
  width: 30%;
  height: content-fit;
  padding-bottom: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  background-color: ${(props) => props.bgColor};
  transition:all 0.3s ease-in;
  ${Mobile({
    height: "contentFit",
    width: "60vw",
  })};
  ${Phone({
    height: "contentFit",
    width: "90vw",
  })};

  ${(props) =>
    props.disabled &&
    `
    opacity: 0.3;
    pointer-events: none;
    filter: blur(1px);
  `}
`;

const HeadingContainer = Styled.div`
  padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ContentContainer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const FormContainer = Styled.div`
  flex: 1;
  width: 100%;
`;

const Form = Styled.form`
  padding: 5px 20px;
`;

const AutoLoginContainer = Styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 20px;
`;

const PartitionContainer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 15px 20px; */
`;

const ButtonContainer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
`;

const TextLink = Styled(Link)`
  text-decoration: none;
  color: ${(props) => props.color || "blue"};
  font-size: 14px;
  margin-top: 10px;
`;

const PasswordContainer = Styled.div`
  position: relative;
`;

const IconContainer = Styled.div`
padding:5px;`;
const TextContainer = Styled.div``;

const Login = () => {
  const { colorTheme, theme, setNotification } = useContext(ContextProvider);
  const { backPri, backSec, fontPri, fontSec } = colorTheme;
  const [loading, setLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleTogglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      setNotification("warning", "Please enter your email");
      return;
    }
    if (!password) {
      setNotification("warning", "Please enter your password");
      return;
    }
    setLoading(true);
    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/auth/login`,
        {
          email: email,
          password: password,
        }
      );
      console.log(data);
      setNotification("success", "Logged-In Successfully");
      dispatch(loginSuccess(data));
      navigate("/home");
    } catch (error) {
      setNotification(
        "failure",
        error.response?.data?.message || "Login failed"
      );
    }
    setLoading(false);
  };

  const handleOauthRegister = async (credentialResponse) => {
    setLoading(true);
    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/auth/google-oauth-login`,
        {
          token: credentialResponse.credential,
        }
      );
      setNotification("success", "Logged-In Successfully");
      dispatch(loginSuccess(data));
      navigate("/home");
    } catch (error) {
      setNotification(
        "failure",
        error.response?.data?.message || "Login failed"
      );
    }
    setLoading(false);
  };

  return (
    <Container bgColor={backPri}>
      <Wrapper disabled={loading} bgColor={backSec}>
        <HeadingContainer>
          <IconContainer>
            <Icon />
          </IconContainer>
          <TextContainer>
            <Text
              ftColor={fontPri}
              val={"Login"}
              ftSize={"30px"}
              ftWeight={"Bolder"}
            />
          </TextContainer>
        </HeadingContainer>
        <ContentContainer>
          <AutoLoginContainer>
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            >
              <GoogleLogin
                theme={theme === "dark" ? "filled_blue" : "outline"}
                shape={"pill"}
                onSuccess={(credentialResponse) =>
                  handleOauthRegister(credentialResponse)
                }
                onError={() => console.log("Login Failed")}
              />
            </GoogleOAuthProvider>
          </AutoLoginContainer>
          <PartitionContainer>
            <Text
              ftColor={fontSec}
              val={"or"}
              ftSize={"20px"}
              ftWeight={"bolder"}
            />
          </PartitionContainer>
          <FormContainer>
            <Form onSubmit={handleSubmit}>
              <InputContainer
                label="email"
                placeholder="Email"
                type="email"
                name="email"
                value={email}
                setInput={setEmail}
              />
              <PasswordContainer>
                <InputContainerWithEye
                  label="password"
                  placeholder="Password"
                  type={passwordVisible ? "text" : "password"}
                  name="password"
                  setInput={setPassword}
                  handleTogglePassword={handleTogglePassword}
                  passwordVisible={passwordVisible}
                />
              </PasswordContainer>
              <ButtonContainer>
                <Button isDisabled={loading} text={"Login"} type="submit" />
              </ButtonContainer>
            </Form>
          </FormContainer>
          <TextLink to="/register" color={fontPri}>
            Don't have an account? Register
          </TextLink>
          <TextLink to="/password-reset" color={fontPri}>
            Forgot password?
          </TextLink>
        </ContentContainer>
      </Wrapper>
    </Container>
  );
};

export default Login;
