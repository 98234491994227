import { createSlice } from "@reduxjs/toolkit";

const activeNoteSlice = createSlice({
  name: "activenote",
  initialState: {
    toggle: false,
    docId: "",
  },
  reducers: {
    setActiveNote: (state, action) => {
      state.toggle = true;
      state.docId = action.payload;
    },
    unsetActiveNote: (state) => {
      state.toggle = false;
    },
  },
});

export const { setActiveNote, unsetActiveNote } = activeNoteSlice.actions;

export default activeNoteSlice.reducer;
