import React, { useState, useEffect, useContext } from "react";
import Styled from "styled-components";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { ContextProvider } from "../App";
import { useNavigate } from "react-router-dom";
import { Mobile } from "../responsive";
import { toggleSidebar } from "../redux/sideReducer";
import { useDispatch } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import Noteshare from "./General/Icon";
import { useSelector } from "react-redux";
import { toggleAccountBar } from "../redux/accountReducer";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

const Container = Styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.backPri};
  color: black;
  position: sticky;
  z-index: 2;
  top: 0px;
  justify-content: center;
  height: 60px;
  padding-left: 15px;
  padding-right: 15px;
  ${Mobile({
    paddingLeft: "10px",
    paddingRight: "10px",
  })};

  .loading-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    background-color: #387ADF;
    animation: ${({ loading }) =>
      loading
        ? "growLoader 1.5s ease-out forwards"
        : "completeLoader 0.5s forwards"};
  }

  @keyframes growLoader {
    0% {
      width: 0%;
    }
    100% {
      width: 75%;
    }
  }

  @keyframes completeLoader {
    0% {
      width: 75%;
    }
    100% {
      width: 100%;
      opacity: 0;
    }
  }
`;

const Wrapper = Styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Left = Styled.div`
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width:content-fit;
`;

const IconContainer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 10px;
`;
const IconContainerMenu = Styled.div`
  
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display:none;
  ${Mobile({ display: "flex" })};
  
`;

const TitleContainer = Styled.div`
  padding: 0px 10px 0px 10px;
  ${Mobile({ display: "none" })};
`;

const Title = Styled.span`
  font-weight: 600;
  font-size: 30px;
  margin: 0px auto;
  
  cursor: pointer;
  color: ${(props) => props.theme.fontPri};
  font-family: "Nunito", sans-serif;
  ${Mobile({ fontSize: "28px" })};
  `;

const Center = Styled.div`
  flex: 2.5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left:13%;
  padding-right:13%;
  ${Mobile({ paddingRight: "1%", paddingLeft: "1%" })};

`;

const InputContainer = Styled.div`
  flex: 9;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  background-color: ${(props) => props.theme.backSec};
  padding: 2px 20px;
  border-right: none;
`;

const Input = Styled.input`
  color: ${(props) => props.theme.fontPri};
  font-size: 18px;
  flex: 1;
  border: none;
  height: 30px;
  outline: none;
  background-color: ${(props) => props.theme.backSec};
  
  ${Mobile({ width: "90%" })};
`;

const SearchIconContainer = Styled.div`
  height: 40px;
  width: 40px;
  border-left: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.backSec};
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: 2px 4px;
`;

const Right = Styled.div`
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const UserContainer = Styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Span = Styled.span`
  font-size: 30px;
  ${Mobile({ display: "none" })};
  color: #387ADF;
  font-family: "Nunito", sans-serif;
  font-weight: 600;
`;

const Navbar = () => {
  const [search, setSearch] = useState("");
  const { colorTheme } = useContext(ContextProvider);
  const [load, setLoad] = useState(false);
  const loader = useSelector((state) => state.load.toggle);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (loader) {
      setLoad(true);
    } else {
      setTimeout(() => {
        setLoad(false);
      }, 500);
    }
  }, [loader]);

  const handleSearch = () => {
    navigate(`/home?search=${search}`);
  };

  const IconStyle = {
    height: "35px",
    width: "35px",
    cursor: "pointer",
    color: colorTheme.fontPri,
  };

  return (
    <Container theme={colorTheme} loading={loader}>
      <Wrapper>
        <Left>
          <IconContainerMenu>
            <MenuRoundedIcon
              style={IconStyle}
              onClick={() => dispatch(toggleSidebar())}
            />
          </IconContainerMenu>
          <IconContainer onClick={() => navigate("/home")}>
            <Noteshare style={IconStyle} />
          </IconContainer>
          <TitleContainer onClick={() => navigate("/home")}>
            <Title theme={colorTheme}>
              Doc
              <Span>Share</Span>
            </Title>
          </TitleContainer>
        </Left>
        <Center>
          <InputContainer theme={colorTheme}>
            <Input
              theme={colorTheme}
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
            />
          </InputContainer>
          <SearchIconContainer theme={colorTheme}>
            <SearchRoundedIcon
              onClick={handleSearch}
              style={{
                height: "32px",
                width: "32px",
                cursor: "pointer",
                color: colorTheme.fontPri,
              }}
            />
          </SearchIconContainer>
        </Center>
        <Right>
          <UserContainer>
            <PersonIcon
              onClick={() => dispatch(toggleAccountBar())}
              style={IconStyle}
            />
          </UserContainer>
        </Right>
      </Wrapper>
      {load && <div className="loading-bar"></div>}
    </Container>
  );
};

export default Navbar;
