import React, { useState, useContext } from "react";
import { ContextProvider } from "../App";
import Text from "../components/General/Text";
import Styled from "styled-components";
import { Phone, Mobile } from "../responsive";
import Button from "../components/General/Button";
import InputContainer from "../components/General/InputContainer";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import Icon from "../components/General/Icon";
import { useSelector, useDispatch } from "react-redux";
import { loginSuccess } from "../redux/userReducer";
const Container = Styled.div`
height:100%;
display:flex;
align-items:center;
justify-content:center;
background-color:${(props) => props.bgColor};
box-sizing:border-box;
overflow:scroll;
&::-webkit-scrollbar{
  display:none;
};
`;

const Wrapper = Styled.div`

width:30%;
height:content-fit;
padding-bottom:20px;
box-sizing:border-box;
border-radius:10px;
background-color:${(props) => props.bgColor};
${Mobile({
  height: "contentFit",
  width: "80vw",
})};
${Phone({
  height: "contentFit",
  width: "80vw",
})};
${(props) =>
  props.disabled &&
  `
    opacity: 0.3;
    pointer-events: none;
    filter: blur(1px);
  `}
`;

const HeadingContainer = Styled.div`
padding: 10px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ContentContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
`;
const InputBox2 = Styled.div`
background-color:${(props) => props.bgColor};
color:${(props) => props.ftColor};
margin-top:10px;
border-radius:5px;
padding:10px 12px;
box-sizing:border-box;
border:none;
margin-bottom:20px;
`;
const FormContainer = Styled.div`
flex:1;
width:100%;
`;

const Form = Styled.form`
padding:5px 20px;
`;

const ButtonContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
padding:10px 10px;
`;

const IconContainer = Styled.div`
padding:5px;`;
const TextContainer = Styled.div``;

const Register = () => {
  const { colorTheme, setNotification } = useContext(ContextProvider);
  const { backPri, backSec, fontPri } = colorTheme;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [OTPSent, setOTPSent] = useState(false);

  const user = useSelector((state) => state.user.currentUser);

  const [OTP, setOTP] = useState("");
  const navigate = useNavigate();
  const email = user?.email;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setNotification("warning", "Please enter your email");
      return;
    }

    setLoading(true);
    try {
      await Axios.post(`${process.env.REACT_APP_API_URL}/api/v1/otp/send-otp`, {
        email: email,
      });

      setNotification("success", "OTP sent successfully");

      setOTPSent(true);
    } catch (error) {
      setNotification(
        "failure",
        error.response?.data?.message || "Registration failed"
      );
    }
    setLoading(false);
  };

  //elements
  const resetPassword = async (e) => {
    e.preventDefault();
    if (!OTP) {
      setNotification("warning", "Please enter your otp");
      return;
    }

    setLoading(true);
    try {
      await Axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/otp/verify-otp`,
        {
          email: email,
          otp: OTP,
        }
      );

      const updatedUserDetail = {
        ...user,
        status: "verified",
      };
      dispatch(loginSuccess(updatedUserDetail));
      setNotification("success", "Email verified successfully");
      navigate("/profile");
    } catch (error) {
      setNotification(
        "failure",
        error.response?.data?.message || "Couldn't Verify"
      );
    }
    setLoading(false);
  };

  return (
    <Container bgColor={backPri}>
      <Wrapper disabled={loading} bgColor={backSec}>
        <HeadingContainer>
          <IconContainer>
            <Icon />
          </IconContainer>
          <TextContainer>
            <Text
              ftColor={fontPri}
              val={"Verify Email"}
              ftSize={"30px"}
              ftWeight={"Bolder"}
            ></Text>
          </TextContainer>
        </HeadingContainer>
        <ContentContainer>
          <FormContainer>
            <Form
              autoComplete="off"
              onSubmit={OTPSent ? resetPassword : handleSubmit}
            >
              {!OTPSent && (
                <InputBox2
                  bgColor={colorTheme.backPri}
                  ftColor={colorTheme.fontPri}
                >
                  {email}
                </InputBox2>
              )}
              {OTPSent && (
                <div>
                  <InputContainer
                    label="OTP"
                    placeholder="OTP"
                    type="number"
                    name="otp"
                    value={OTP}
                    setInput={setOTP}
                  />
                </div>
              )}
              <ButtonContainer>
                <Button
                  isDisabled={loading}
                  text={OTPSent ? "Verify Email" : "Send OTP"}
                  type="submit"
                ></Button>
              </ButtonContainer>
            </Form>
          </FormContainer>
        </ContentContainer>
      </Wrapper>
    </Container>
  );
};

export default Register;
