import React, { useContext } from "react";
import { ContextProvider } from "../App";
import Navbar from "../components/Navbar";
import Styled from "styled-components";
import Sidebar from "../components/Sidebar";
import Content from "../components/Content";
import AccountContainer from "../components/AccountContainer";
import ReadDoc from "../pages/ReadDoc";
import SidebarMobile from "../components/SidebarMobile";

const Container = Styled.div`
overflow-y:scroll;
&::-webkit-scrollbar{
  display:none;
};
background-color:${(props) => props.theme.backSec};`;

const Wrapper = Styled.div`
position:relative;
display:flex;
align-items:flex-start;
justify-content:center;


`;
const Home = () => {
  const { colorTheme } = useContext(ContextProvider);

  return (
    <Container theme={colorTheme}>
      <AccountContainer />
      <ReadDoc />
      <Navbar />
      <Wrapper className="parent">
        <Sidebar />
        <SidebarMobile></SidebarMobile>
        <Content />
      </Wrapper>
    </Container>
  );
};

export default Home;
