import React, { useContext, useState, useEffect, useRef } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../App";
import RenderNote from "./RenderNote";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux/userReducer";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadDoneRoundedIcon from "@mui/icons-material/DownloadDoneRounded";
import { useSelector, useDispatch } from "react-redux";
import Loader from "./Loader";
import axios from "axios";
const Container = Styled.div`
  background-color:${(props) => props.theme.backSec};
  padding:20px;
  box-sizing:border-box;
  color:${(props) => props.theme.fontPri};
  border-radius:10px;
  height:calc(100vh - 70px);
  height:${(props) => props.isMobile && "50vh"};
  width:100%;
`;

const Wrapper = Styled.div`
  height:100%;
  position:relative;
  top:0px;
  bottom:0px;
  left:0px;
  right:0px;
  overflow:scroll;
  &::-webkit-scrollbar{
      display:none;
  }
`;

const Input = Styled.input`
  flex:1;
  width:100%;
  height:30px;
  padding:3px 5px;
  background-color:${(props) => props.theme.backPri};
  color:${(props) => props.theme.fontPri};
  border:none;
  border-radius:10px;
  outline:none;
`;

const TopContainer = Styled.div`
  background-color:${(props) => props.theme.backSec};
  
  position:sticky;
  top:0px;
  z-index:10;
`;

const HeadContainer = Styled.div`
  width:100%;
  display:flex;
  align-items:center;
  justify-content:stretch;
`;

const IconContainer = Styled.div`
  height:35px;
  width:35px;
`;

const InputContainer = Styled.div`
  width:100%;
  padding-left:20px;
  padding-right:20px;
  box-sizing:border-box;
`;

const ToolContainer = Styled.div`
  height:content-fit;
  width:100%;
  padding:10px 0px;
  box-sizing:border-box;
  display:flex;
  align-items:center;
  justify-content:space-around;
`;

const AppendContainer = Styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;  
  height: 40px;
  background-color: ${(props) => props.theme.backPri};
  cursor: pointer;
  flex-shrink: 0;  
`;

const Span = Styled.span`
  font-weight:bold;
`;

const NoteContainer = Styled.div`
  flex:1;
  width:100%;
  overflow:scroll;
  box-sizing:border-box;
  &::-webkit-scrollbar{
      display:none;
  };
`;

const NoteWrapper = Styled.div`
  position:relative;
  margin-bottom: 10px;
  &:hover .overlay {
    opacity: 1;
  }
`;

const Overlay = Styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

const IconWrapper = Styled.div`
  display: flex;
  gap: 10px;
`;

const Notes = ({ toggleNote, isMobile }) => {
  const { colorTheme, setNotification } = useContext(ContextProvider);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const divRef = useRef(null);
  const [selectedVar, setSelectedVar] = useState("");
  const [notes, setNotes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [noteToEdit, setNoteToEdit] = useState(null);
  const [initiallyFetched, setInitiallyFetched] = useState([]);
  const user = useSelector((state) => state.user.currentUser);
  const activeNote = useSelector((state) => state.activenote);
  const [saved, setSaved] = useState(false);
  const [fetchNotes, setFetchNotes] = useState(false);
  const [savedNotes, setSavedNotes] = useState(false);
  const IconStyle = {
    height: "35px",
    width: "35px",
    cursor: "pointer",
  };

  useEffect(() => {
    const handleSelectionChange = () => {
      const selection = window.getSelection().toString();
      if (selection.length !== 0) setSelectedVar(selection);
    };

    document.addEventListener("selectionchange", handleSelectionChange);

    return () => {
      document.removeEventListener("selectionchange", handleSelectionChange);
    };
  }, []);
  useEffect(() => {
    const fetchNotes = async () => {
      setFetchNotes(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/notes/getNotesByRelated/${activeNote.docId}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );

        const result = response.data.contents.map((item) => {
          return {
            content: item.id.content,
            type: item.id.type,
            _id: response.data._id,
          };
        });
        setInitiallyFetched(result);
        setNotes(result);
      } catch (error) {
        if (error?.response?.status === 411) {
          setNotification("failure", "Sessions Exceeded! Please login again");
          dispatch(logout());
          navigate("/login");
          return;
        }
      }
      setFetchNotes(false);
    };

    fetchNotes();
  }, [activeNote, user.access_token]);

  // Handle save with axios for both create and update
  const handleSave = async () => {
    setSavedNotes(true);
    try {
      if (!initiallyFetched || initiallyFetched.length === 0) {
        // Create new notes
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/notes/createNotes`,
          {
            contents: notes.map((note) => ({
              content: note.content,
              type: note.type,
            })),
            relatedTo: activeNote.docId, // Use the related document ID
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );

        setSaved(true);
      } else {
        // Update existing notes
        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/v1/notes/updateNotesById/${notes[0]._id}`,
          {
            contents: notes.map((note) => ({
              content: note.content,
              type: note.type,
            })),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.access_token}`,
            },
          }
        );

        setSaved(true);
      }
    } catch (error) {
      if (error?.response?.status === 411) {
        setNotification("failure", "Sessions Exceeded! Please login again");
        dispatch(logout());
        navigate("/login");
        return;
      }
    }
    setSavedNotes(false);
  };

  const addNote = (type, content) => {
    if (editMode && noteToEdit !== null) {
      updateNote(noteToEdit, selectedVar);
      setSaved(false);
      return;
    }
    setSelectedVar("");
    const newNote = { type, content, note: "" };
    setNotes((prevNotes) => [...prevNotes, newNote]);
    setSaved(false);
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  };
  const appendToLastNote = (content) => {
    setNotes((prevNotes) => {
      if (prevNotes.length > 0) {
        const updatedNotes = [...prevNotes];
        updatedNotes[updatedNotes.length - 1].content += ` ${content}`;
        return updatedNotes;
      }
      setSaved(false);
      return prevNotes;
    });
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  };

  const handleClickA = (val) => {
    appendToLastNote(val);
    setSelectedVar("");
  };

  const handleClickH1 = (val) => {
    if (val === "") {
      return;
    }
    addNote("heading1", val);
    setSelectedVar("");
  };

  const handleClickH2 = (val) => {
    if (val === "") {
      return;
    }
    addNote("heading2", val);
    setSelectedVar("");
  };

  const handleClickH3 = (val) => {
    if (val === "") {
      return;
    }
    addNote("heading3", val);
    setSelectedVar("");
  };

  const handleClickP = (val) => {
    if (val === "") {
      return;
    }
    addNote("paragraph", val);
    setSelectedVar("");
  };

  const handleClickL = (val) => {
    if (val === "") {
      return;
    }
    addNote("list", val);
    setSelectedVar("");
  };
  const updateNote = (index, content) => {
    setNotes((prevNotes) => {
      const updatedNotes = [...prevNotes];
      updatedNotes[index].content = content;
      return updatedNotes;
    });
    setEditMode(false);
    setSelectedVar("");
    setNoteToEdit(null);
  };

  const deleteNote = (index) => {
    setSaved(false);
    setNotes((prevNotes) => prevNotes.filter((_, i) => i !== index));
  };

  const handleNoteClick = (index) => {
    const note = notes[index];
    setSelectedVar(note.content);
    setEditMode(true);
    setNoteToEdit(index);
  };

  return (
    <Container isMobile={isMobile} theme={colorTheme}>
      <Wrapper ref={divRef}>
        <TopContainer theme={colorTheme}>
          <HeadContainer>
            <IconContainer>
              {saved ? (
                <DownloadDoneRoundedIcon
                  style={IconStyle}
                  onClick={handleSave}
                />
              ) : savedNotes ? (
                <Loader />
              ) : (
                <SaveRoundedIcon style={IconStyle} onClick={handleSave} />
              )}
            </IconContainer>
            <InputContainer>
              <Input
                theme={colorTheme}
                value={selectedVar}
                onChange={(e) => setSelectedVar(e.target.value)}
              />
            </InputContainer>
            <IconContainer>
              <CloseRoundedIcon
                style={IconStyle}
                onClick={() => toggleNote()}
              />
            </IconContainer>
          </HeadContainer>
          <ToolContainer>
            <AppendContainer
              theme={colorTheme}
              onClick={() => handleClickA(selectedVar)}
            >
              <Span>A</Span>
            </AppendContainer>
            <AppendContainer
              theme={colorTheme}
              onClick={() => handleClickH1(selectedVar)}
            >
              <Span>H1</Span>
            </AppendContainer>
            <AppendContainer
              theme={colorTheme}
              onClick={() => handleClickH2(selectedVar)}
            >
              <Span>H2</Span>
            </AppendContainer>
            <AppendContainer
              theme={colorTheme}
              onClick={() => handleClickH3(selectedVar)}
            >
              <Span>H3</Span>
            </AppendContainer>
            <AppendContainer
              theme={colorTheme}
              onClick={() => handleClickP(selectedVar)}
            >
              <Span>P</Span>
            </AppendContainer>
            <AppendContainer
              theme={colorTheme}
              onClick={() => handleClickL(selectedVar)}
            >
              <Span>L</Span>
            </AppendContainer>
          </ToolContainer>
        </TopContainer>
        <NoteContainer>
          {fetchNotes ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Loader />
            </div>
          ) : (
            notes.map((note, index) => (
              <NoteWrapper key={index}>
                <div onClick={() => handleNoteClick(index)}>
                  <RenderNote blogData={{ contents: [note] }} />
                </div>
                <Overlay className="overlay">
                  <IconWrapper>
                    <EditIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => handleNoteClick(index)}
                    />
                    <DeleteIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => deleteNote(index)}
                    />
                  </IconWrapper>
                </Overlay>
              </NoteWrapper>
            ))
          )}
        </NoteContainer>
      </Wrapper>
    </Container>
  );
};

export default Notes;
