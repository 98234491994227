import { configureStore, combineReducers } from "@reduxjs/toolkit";

import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from "./userReducer";
import sideReducer from "./sideReducer";
import personReducer from "./personReducer";
import accountReducer from "./accountReducer";
import warningReducer from "./warningReducer";
import themeReducer from "./themeReducer";
import loadReducer from "./loadReducer";
import activeNoteReducer from "./activeNoteReducer";
const persistConfig = {
  key: "projectshare:root",
  version: 1,
  storage,
  blacklist: ["activenote"],
};
const rootReducer = combineReducers({
  user: userReducer,
  sidebar: sideReducer,
  personbar: personReducer,
  accountbar: accountReducer,
  warning: warningReducer,
  theme: themeReducer,
  load: loadReducer,
  activenote: activeNoteReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
